import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Moment from 'react-moment'

interface PostItemData {
  to: string
  img?: any
  alt?: any
  date: string
  terms: any
  className?: string
  children?: React.ReactNode
  classNameText?: string
  readMoreString?: string
}

const PostItemRow = ({
  to,
  img,
  alt,
  date,
  terms,
  className = '',
  classNameText = '',
  children,
  readMoreString = 'Lue lisää',
}: PostItemData) => {
  return (
    <Link className={`flex flex-row hover:text-blue focus:text-blue hover-scale-img ${className}`} to={to}>
      {img && <GatsbyImage className="sm:w-1/2 flex-grow-0 flex-shrink-0" image={img} alt={alt ?? ''} />}
      <div className={`flex flex-1 flex-col ${classNameText}`}>
        <div className={`flex`}>
          <p className="text-xl">
            <Moment format="DD.MM.YYYY" date={date} />
          </p>
          {terms && (
            <p className="ml-auto text-xl flex">
              {terms.map((term: any, i: number, row: any) => {
                if (i + 1 === row.length) {
                  return (
                    <span className="flex items-center" key={term.id}>
                      {term.name}
                    </span>
                  )
                } else {
                  return (
                    <span className="after-line-news flex items-center mr-4" key={term.id}>
                      {term.name}
                    </span>
                  )
                }
              })}
            </p>
          )}
        </div>
        {children}
        <p className="pt-6 mt-auto font-bold hidden xs:inline uppercase tracking-full">{readMoreString}</p>
      </div>
    </Link>
  )
}

export default PostItemRow
