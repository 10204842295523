import React, { useState, useEffect } from 'react'
import PostListItem from '@/components/PostListItem'
import Book from '@/components/BookGrid/Book'
import { getImage } from 'gatsby-plugin-image'
import PostItem from '@/components/PostItem'
import { excerpt } from '@/functions'
import PostItemRow from '@/components/PostItem/PostItemRow'
import SimpleFeedItem from '@/components/SimpleFeed/Item'

interface LoadMore {
  posts: any
  className?: string
  classNameButton?: string
  postType?: string
  total?: number
  load?: number
  buttonText?: string
  reduceByone?: boolean
  readMoreString?: string
}

const LoadMore = ({
  posts,
  load = 4,
  reduceByone = false,
  total,
  className = '',
  classNameButton = '',
  postType = 'posts',
  buttonText = 'Katso lisää',
  readMoreString = 'Lue lisää',
}: LoadMore) => {
  const [reduce, setReduce] = useState(0)
  const numberPosts = load - reduce || 4
  // const totalposts = total || 0
  const [paginatedPosts, setPaginatedPosts] = useState([...posts.slice(0, numberPosts)])
  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(posts.length > numberPosts)

  const handleLoadMore = () => {
    setLoadMore(true)
  }
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = paginatedPosts.length
      const isMore = currentLength < posts.length
      const nextResults = isMore ? posts.slice(currentLength, currentLength + numberPosts) : []
      setPaginatedPosts([...paginatedPosts, ...nextResults])
      setLoadMore(false)
    }
    if (reduceByone) {
      setReduce(1)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = paginatedPosts.length < posts.length
    setHasMore(isMore)
  }, [paginatedPosts])

  return (
    <>
      <div className={`${className}`}>
        {postType == 'MeltWater' && (
          <ul className="border-t border-lightgray">
            {paginatedPosts.map((item: any) => {
              return (
                <SimpleFeedItem
                  title={item.title}
                  link={item.link}
                  key={item.id}
                  pubDate={item.pubDate}
                  content={item.content}
                />
              )
            })}
          </ul>
        )}
        {postType == 'events' &&
          paginatedPosts.map((event: any) => {
            return (
              <PostListItem
                key={event.id}
                date={event.gwEventsDate}
                heading={event.title}
                content={event.gwEventsFirstSection}
                uri={event.uri}
              />
            )
          })}
        {postType == 'books' &&
          paginatedPosts.map((book: any, index: number) => {
            const image: any = getImage(book?.featuredImage?.node?.localFile)
            return (
              <Book
                number={book?.gwBooksNumber}
                key={index}
                to={book.uri}
                image={image}
                year={book.gwBooksYear}
                alt={book?.featuredImage?.node?.altText}
                content={book?.gwBooksLongSummary}
                boldContent={book?.gwBooksShortSummary}
              />
            )
          })}
        {postType == 'posts' &&
          paginatedPosts.map((post: any, index: number) => {
            const image: any = getImage(post?.featuredImage?.node?.localFile)
            const alt: string = post?.featuredImage?.node?.altText || 'post image'

            if (index === 0) {
              return (
                <PostItemRow
                  readMoreString={readMoreString}
                  key={post.id}
                  to={post.uri}
                  img={image}
                  alt={alt}
                  date={post.date}
                  className={`bg-lighterlatte sm:col-span-2 flex-col sm:flex-row`}
                  classNameText="px-6 py-8 sm:p-10"
                  terms={post?.terms?.nodes}
                >
                  <h3 className="sm:text-2xl md:text-4xl mt-4 mb-4 break-words">{post.title}</h3>
                  <p>{excerpt(post.content)}</p>
                </PostItemRow>
              )
            } else {
              return (
                <PostItem
                  readMoreString={readMoreString}
                  key={post.id}
                  to={post.uri}
                  img={image}
                  alt={alt}
                  date={post.date}
                  className={`bg-lighterlatte`}
                  classNameText="px-6 py-8"
                  terms={post?.terms?.nodes}
                >
                  <h3 className={`sm:text-2xl md:text-4xl mt-4 break-words`}>{post.title}</h3>
                </PostItem>
              )
            }
          })}
      </div>
      <div className={`${classNameButton}`}>
        {hasMore ? (
          <button
            className="roundedButton hover:text-white hover:bg-black hover:border-black focus:text-white focus:bg-black focus:border-black"
            onClick={handleLoadMore}
          >
            {buttonText}
          </button>
        ) : (
          <p>Ei enempää julkaisuja</p>
        )}
      </div>
    </>
  )
}
export default LoadMore
