import React from 'react'
import Image from '@/components/Image'
import { Link } from 'gatsby'
import { trimWords } from '@/functions'

interface BookData {
  image: any
  alt: string
  to: string
  year: string
  number: number
  boldContent: string
  content: string
}

const Book = ({ image, alt, to, year, number, boldContent, content }: BookData) => {
  return (
    <Link className="group hover-scale-img" to={to}>
      <div className="flex mb-3">
        <div className="py-2 px-3 text-3xl font-bold text-latte group-hover:text-hovbrown  group-focus:text-hovbrown transition-all">
          <span>{year}</span>
        </div>
        {number && (
          <div className="py-2 px-3 ml-auto text-3xl font-bold text-white bg-latte group-hover:bg-hovbrown group-focus:bg-hovbrown transition-all">
            <span>{number}</span>
          </div>
        )}
      </div>
      <div className="">{image && <Image image={image} alt={alt} />}</div>
      <div className="">
        <h3 className="text-blue group-hover:text-hovbrown group-focus:text-hovbrown mb-2 mt-4 sm:mt-8 text-2xl transition-all">
          {trimWords(boldContent, 6)}
        </h3>
        <p className="font-bold">{trimWords(content, 15)}</p>
      </div>
    </Link>
  )
}
export default Book
