import React from 'react'
import Moment from 'react-moment'
import { excerpt } from '@/functions'

interface ItemData {
  title?: string
  link: string
  pubDate?: string
  content?: any
}

const Item = ({ title, link, pubDate, content }: ItemData) => {
  return (
    <li className="border-b border-lightgray py-5 sm:flex">
      <div className="w-full sm:w-1/3 text-gray flex-shrink-0 flex-grow-0 tracking-widest flex md:items-center md:flex-row flex-col sm:mb-0 mb-4">
        <span className="font-normal text-md md:mb-0 mb-2">
          <Moment format="DD.MM.YYYY" date={pubDate} />
        </span>{' '}
        <span className="mx-3 hidden md:inline"> – </span>{' '}
        <h3 className="font-normal wordbreak text-md">{excerpt(title, 4, true)}</h3>
      </div>
      {link && (
        <a
          target="_blank"
          className="text-blue font-bold hover:text-hovbrown focus:text-hovbrown sm:ml-4 wordbreak"
          href={link}
          rel="noreferrer"
        >
          {excerpt(content, 12, true)}
        </a>
      )}
    </li>
  )
}
export default Item
